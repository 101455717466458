@import url(../node_modules/mapbox-gl/dist/mapbox-gl.css);

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0c0d0e;
  overflow: hidden;
}

body,
input,
button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

a {
  color: #4285f4;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

button {
  font-size: 1em;
  cursor: pointer;
  border: 1px solid #4285f4;
  border-radius: 5px;
  padding: 10px;
  color: #4285f4;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
}
button:active {
  color: #fff;
  background-color: #4285f4;
}

* {
  box-sizing: border-box;
}

header {
  padding: 10px;
  cursor: pointer;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  z-index: 1;
}

header:active {
  opacity: 0.7;
}

header h1 {
  margin: 0;
  padding: 0;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

header svg {
  vertical-align: top;
}

#about {
  color: #333;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 3;
  padding: 15px;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.15, 0.78, 0.18, 1.17);
  transition: transform 0.3s cubic-bezier(0.15, 0.78, 0.18, 1.17);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  pointer-events: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-size: 0.9em;
}

#about.show {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

@media screen and (min-width: 500px) and (min-height: 500px) {
  #about {
    width: 500px;
    left: 50%;
    margin-left: -250px;
    border-radius: 12px;
  }
  #about.show {
    transform: translate3d(0, 15px, 0);
    -webkit-transform: translate3d(0, 15px, 0);
  }
}

#about h2,
#about h3 {
  margin: 0;
  padding: 0 0 0.5em;
}

#about p {
  margin: 0;
  padding: 0 0 0.5em;
  line-height: 1.4;
}

#about ul {
  list-style: none;
  margin: 0 0 5px;
  padding: 0;
}
#about ul li {
  display: inline-block;
  margin-right: 0.4em;
}
#about ul li img {
  vertical-align: middle;
  transform: scale(0.5);
}

#about button {
  width: 100%;
  margin: 0;
}

#preview {
  float: right;
  border: 1px solid #999;
  margin-left: 10px;
  margin-top: 5px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

#info-taxis {
  pointer-events: none;
  font-size: 0.85em;
  position: absolute;
  bottom: 40px;
  right: 68px;
  margin-left: 10px;
  border-radius: 16px;
  padding: 10px 16px;
  text-align: right;
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(20px) saturate(2);
  backdrop-filter: blur(20px) saturate(2);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-animation: blink 0.75s linear infinite alternate;
  animation: blink 0.75s linear infinite alternate;
}
#info-taxis.loaded {
  -webkit-animation: jump 0.3s;
  animation: jump 0.3s;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

#location {
  display: none;
  outline: 0;
  position: absolute;
  bottom: 40px;
  right: 10px;
  z-index: 1;
  border: 0;
  padding: 12px;
  margin: 0;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  vertical-align: top;
  transition: transform 0.3s ease;
}
#location svg {
  fill: #666;
}
#location .compass,
#location.compass .location {
  display: none;
}
#location.compass .compass {
  display: block;
}
#location.locating {
  pointer-events: none;
}
#location.locating svg {
  -webkit-animation: rotate 5s infinite both linear;
  animation: rotate 5s infinite both linear;
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
#location.active svg {
  fill: #4285f4;
}

.mapboxgl-ctrl-custom-pitch {
  font-weight: bold;
  color: #000;
  font-size: 15px;
  letter-spacing: -0.5px;
}
.active .mapboxgl-ctrl-custom-pitch {
  color: #4285f4;
}
.mapboxgl-ctrl-custom-pitch span {
  display: block;
  transition: transform 0.3s ease-in-out;
}

#current-location {
  width: 60px;
  height: 60px;
}
#current-location-circle {
  width: 18px;
  height: 18px;
  background-color: #4285f4;
  border: 3px solid #fff;
  border-radius: 123123px;
  overflow: hidden;
  line-height: 0;
  margin-top: 21px;
  margin-left: 21px;
  box-shadow: 0 0 15px rgba(66, 133, 244, 0.9);
  position: relative;
  z-index: 1;
}
#current-location-viewport {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(to bottom, rgba(66, 133, 244, 0), #4285f4 30%);
  clip-path: polygon(25% 0, 75% 0, 50% 50%);
}

.mapboxgl-ctrl {
  box-sizing: content-box;
}
